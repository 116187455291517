<template>
  <div class="loader-overlay text-center d-flex align-items-center justify-content-center">
    <div>
      <Loader
        :color="color"
        :size="size"
      />
    </div>
  </div>
</template>

<script>

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'LoaderOverlay',
  props: ['size', 'color'],
  components: {
    Loader
  }
}
</script>

<style lang="scss" scoped>
  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(255,255,255,0.5);
  }
</style>
