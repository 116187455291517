<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{pageTitle}} cupom</h1>
        <!--end::Title-->
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'coupons.show', params: { id: coupon.id } }"
            class="btn btn-sm btn-primary"
            v-if="coupon"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
     <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template>
          <form class="default-form pt-10" @submit.prevent="">
            <LoaderOverlay
              size="big"
              color="color2"
              v-if="overlayLoader"
            />
            <div class="d-flex mb-5">
              <div class="col-12 col-lg-4 pe-lg-5">
                <label class="required form-label">Tipo</label>
                <select class="form-select" v-model.trim="form.type">
                  <option value="CREDIT">Crédito</option>
                  <option value="SPEND_EARN">Gaste e Ganhe</option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-column flex-lg-row mb-5">
              <div class="col-12 col-lg-4 pe-lg-5">
                <label class="required form-label">Nome</label>
                <input class="form-control" type="text" v-model="form.name">
              </div>
              <div class="col-12 col-lg-4 pe-lg-5">
                <label class="required form-label">Código</label>
                <input class="form-control" type="text" v-model="form.code">
              </div>
            </div>
            <div class="d-flex flex-column flex-lg-row mb-5">
              <div class="col-12 col-lg-3 pe-lg-5" v-if="form.type == 'SPEND_EARN'">
                <label class="required form-label">Gaste</label>
                <Money
                  class="form-control mb-3"
                  v-model.trim="form.spend_amount"
                  v-bind="money"
                />
              </div>
              <div class="col-12 col-lg-3 pe-lg-5">
                <label class="required form-label">{{ form.type == 'SPEND_EARN' ? 'Ganhe' : 'Valor' }}</label>
                <Money
                  class="form-control mb-3"
                  v-model.trim="form.amount"
                  v-bind="money"
                />
              </div>
              <div class="col-12 col-lg-3 pe-lg-5 mb-5">
                <label class="required form-label">Início de validade para vinculação</label>
                <DateTimeInput
                  v-model.trim="form.start_date"
                  format="dd/MM/yyyy"
                  type="date"
                  theme="basic"
                  class="w-100"
                />
              </div>
              <div class="col-12 col-lg-3 mb-5">
                <label class="required form-label">Término de validade para vinculação</label>
                <DateTimeInput
                  v-model.trim="form.end_date"
                  format="dd/MM/yyyy"
                  type="date"
                  theme="basic"
                  class="w-100"
                  :min="true"
                />
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row mb-5 align-items-center justify-content-between">
              <div class="col-12 col-md-2">
                <label class="required form-label">Número máximo de usos</label>
                <input class="form-control" type="number" v-model="form.max_uses" min="1">
              </div>
              <div class="col-12 col-md-3 pt-5 form-check">
                <input class="form-check-input" type="checkbox" v-model="form.is_first_purchase_use" id="isFirstPurchaseUse">
                <label class="form-check-label" for="isFirstPurchaseUse">Apenas para primeira compra</label>
              </div>
              <div class="col-12 col-md-3 pt-5 form-check">
                <input class="form-check-input" type="checkbox" v-model="form.is_single_use_per_advertiser" id="isSingleUsePerAdvertiser">
                <label class="form-check-label" for="isSingleUsePerAdvertiser">Uso único por advertiser</label>
              </div>
              <div class="col-12 col-md-3 pt-5 form-check">
                <input class="form-check-input" type="checkbox" v-model="form.is_single_use_per_account" id="isSingleUsePerAccount">
                <label class="form-check-label" for="isSingleUsePerAccount">Uso único por conta</label>
              </div>
            </div>
            <div class="mb-5 d-flex">
              <div class="col-12 col-lg-4 pe-lg-5">
                <label class="form-label">Descrição</label>
                <textarea v-model="form.description" class="form-control" rows="2"></textarea>
              </div>
            </div>
            <div class="mt-3 text-center">
              <Button
                class="btn btn-primary"
                @click="submit"
                :loading="loader"
              >
                {{ isEditPage ? 'Salvar cupom' : 'Criar cupom' }}
              </Button>
            </div>
          </form>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import CouponsService from '@/modules/coupons/services/coupons-service'

import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import MainLayout from '@/components/layouts/MainLayout'
import { Money } from 'v-money'

export default {
  components: {
    Button,
    DateTimeInput,
    LoaderOverlay,
    MainLayout,
    Money
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
      coupon: null,
      loader: false,
      overlayLoader: false,
      form: {
        name: '',
        type: '',
        amount: 0,
        spend_amount: 0,
        code: '',
        start_date: '',
        end_date: '',
        description: '',
        max_uses: 1,
        is_first_purchase_use: false,
        is_single_use_per_account: false,
        is_single_use_per_advertiser: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    }
  },
  created () {
    if (this.isEditPage) {
      this.getCoupon()
    }
  },
  computed: {
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'coupons.edit'
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'coupons.create' ? 'Criar' : 'Editar'
    }
  },
  methods: {
    /**
     * Get coupon
     */
    async getCoupon () {
      this.overlayLoader = true
      try {
        const coupon = await CouponsService.getCoupon(this.$route.params.id)
        this.coupon = coupon
        this.setCoupon(coupon)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.overlayLoader = false
    },
    /**
     * Set coupon
     */
    setCoupon (coupon) {
      this.form.name = coupon.name
      this.form.type = coupon.type
      this.form.amount = (coupon.amount / 100)
      this.form.spend_amount = coupon.rules.SPEND_EARN ? (coupon.rules.SPEND_EARN.spend / 100) : 0
      this.form.code = coupon.code
      this.form.start_date = coupon.start_date
      this.form.end_date = coupon.end_date
      this.form.max_uses = coupon.max_uses
      this.form.is_first_purchase_use = coupon.is_first_purchase_use
      this.form.is_single_use_per_account = coupon.is_single_use_per_account
      this.form.is_single_use_per_advertiser = coupon.is_single_use_per_advertiser
      this.form.description = coupon.description
    },
    /**
     * Submit
     */
    async submit () {
      this.overlayLoader = true
      try {
        const form = JSON.parse(JSON.stringify(this.form))

        let coupon = null
        if (!this.coupon) {
          coupon = await CouponsService.createCoupon(form)
        } else {
          coupon = await CouponsService.updateCoupon(this.coupon.id, form)
        }

        this.$router.push({ name: 'coupons.show', params: { id: coupon.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    }
  }
}
</script>
